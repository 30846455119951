import React from "react"

const ClearspaceMonogram = () => {
  return (
    <svg
      viewBox="0 0 122 122"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-image="icon"
      focusable="false"
    >
      <path d="M121 1H1v120h120V1z" fill="#F5FCFF" />
      <path fill="#fff" d="M21 21h80v80H21z" />
      <path
        d="M11 121c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10zM111 21c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10zM1 21h120M1 61h120M1 41h120M1 81h120M1 101h120M21 1v120M41 1v120M61 1v120M81 1v120M101 1v120"
        stroke="#DBF0FF"
        strokeMiterlimit="10"
      />
      <circle cx="61" cy="61" r="59.5" stroke="#D459AB" />
      <path
        d="M92.429 61h-20v5.714h13.714c-2 8.572-8.286 15.714-16.572 18.572L36.714 52.428c3.429-10 13.143-17.142 24.286-17.142 8.571 0 16.286 4.285 21.143 10.857l4.286-3.715C80.714 34.714 71.57 29.571 61 29.571c-14.857 0-27.429 10.572-30.571 24.572l37.714 37.714c13.714-3.429 24.286-16 24.286-30.857zm-62.858.286c0 8 3.143 15.714 9.143 21.714 6 6 14 9.143 21.715 9.143L29.57 61.286z"
        fill="#fff"
      />
      <path
        d="M61 21c-22 0-40 18-40 40s18 40 40 40 40-18 40-40-18-40-40-40zM38.714 83.286c-6-6-9.143-14-9.143-21.715L60.714 92.43c-8-.286-16-3.143-22-9.143zm29.143 8.285L30.43 54.143c3.14-14 15.713-24.573 30.57-24.573 10.571 0 19.714 5.143 25.429 12.858l-4.286 3.714C77.286 39.57 69.57 35.286 61 35.286c-11.143 0-20.571 7.143-24.286 17.143l32.857 32.857c8.286-2.857 14.572-10 16.572-18.572H72.429V61h20c0 14.857-10.572 27.429-24.572 30.571z"
        fill="#639"
      />
    </svg>
  )
}

export default ClearspaceMonogram
