import React from "react"

const Wordmark = () => {
  return (
    <svg
      viewBox="0 0 273 89"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-image="icon"
      focusable="false"
    >
      <path
        d="M99.782 27.342h11.221v40h-11.22V62.17c-4.05 5.82-9.384 6.465-12.235 6.465-12.232 0-20.328-9.606-20.328-21.338 0-11.824 8.004-21.247 19.777-21.247 3.035 0 9.014.555 12.785 6.467v-5.175zM78.81 47.297c0 6.373 4.6 11.362 11.037 11.362 6.44 0 11.039-4.989 11.039-11.362 0-6.375-4.6-11.27-11.04-11.27-6.437 0-11.036 4.895-11.036 11.27zm54.072-10.347v30.393H121.66V36.95h-4.414v-9.608h4.414v-13.67h11.222v13.67h7.727v9.608h-7.727zm33.857-.092c-2.668-2.493-5.336-2.68-6.531-2.68-2.668 0-4.324 1.295-4.324 3.235 0 1.016.46 2.585 3.587 3.602l2.668.831c3.127 1.016 7.819 2.588 10.117 5.728 1.196 1.663 2.024 4.064 2.024 6.652 0 3.601-1.196 7.205-4.414 10.16-3.221 2.957-7.082 4.25-11.957 4.25-8.279 0-12.971-3.973-15.453-6.65l5.887-6.836c2.207 2.585 5.517 4.617 8.83 4.617 3.127 0 5.517-1.57 5.517-4.34 0-2.496-2.023-3.512-3.494-4.065l-2.576-.924c-2.852-1.016-6.162-2.31-8.553-4.804-1.839-1.94-3.037-4.435-3.037-7.668 0-3.88 1.84-7.113 4.141-9.052 3.127-2.496 7.173-2.864 10.392-2.864 2.944 0 7.635.368 12.693 4.248l-5.517 6.56zm24.307-4.341c3.955-5.543 9.567-6.467 12.969-6.467 11.773 0 19.591 9.145 19.591 21.247 0 12.194-8.093 21.339-19.867 21.339-2.575 0-8.37-.462-12.693-6.466v5.173h-11.222V0h11.222v32.517zm-1.103 14.78c0 6.374 4.597 11.362 11.037 11.362 6.439 0 11.037-4.988 11.037-11.361 0-6.376-4.598-11.272-11.037-11.272-6.44 0-11.037 4.896-11.037 11.271zm51.89 14.041l-18.949-33.996h13.063l12.324 22.725 11.037-22.725h12.693l-32.099 61.154h-12.693l14.624-27.158zM64.1 34.05H36.07v11.085h14.617c-2.217 7.447-7.856 12.67-18.559 12.67-11.63 0-19.834-9.501-19.834-21.182 0-11.68 8.204-21.182 19.834-21.182 6.559 0 12.67 3.079 16.656 8.252l9.387-5.892C52.132 9.382 42.49 4.355 32.128 4.355 14.412 4.355 0 18.83 0 36.623c0 17.794 13.403 32.268 32.128 32.268s32.128-14.475 32.128-32.268c0-.871-.088-1.72-.155-2.573z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Wordmark
