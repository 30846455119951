import React from "react"

const Clearspace = () => (
  <svg
    viewBox="0 0 506 194"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-image="icon"
    focusable="false"
  >
    <path d="M505 1H1v192h504V1z" fill="#F5FCFF" />
    <path fill="#fff" d="M41 41h424v112H41z" />
    <path
      d="M1 41h504M41 1v192M1 153h504M1 53h503.6M1 141h503.6M1 129h504M153 1v192M1 65h504M1 89h504M193 1v191.6M465 1v192M21 41c11.046 0 20-8.954 20-20S32.046 1 21 1 1 9.954 1 21s8.954 20 20 20zm152 152c11.046 0 20-8.954 20-20s-8.954-20-20-20-20 8.954-20 20 8.954 20 20 20zm312-64c11.046 0 20-8.954 20-20s-8.954-20-20-20-20 8.954-20 20 8.954 20 20 20zm0-88c11.046 0 20-8.954 20-20s-8.954-20-20-20-20 8.954-20 20 8.954 20 20 20z"
      stroke="#DBF0FF"
      strokeMiterlimit="10"
    />
    <path
      d="M97 193c-53.2 0-96-42.8-96-96S43.8 1 97 1"
      stroke="#D459AB"
      strokeMiterlimit="10"
    />
    <path
      d="M292.782 88.343h11.221v40h-11.221v-5.173c-4.049 5.82-9.383 6.466-12.234 6.466-12.232 0-20.328-9.606-20.328-21.338 0-11.825 8.004-21.248 19.777-21.248 3.035 0 9.014.555 12.785 6.467v-5.174zm-20.972 19.955c0 6.373 4.599 11.361 11.037 11.361 6.439 0 11.039-4.988 11.039-11.361 0-6.376-4.6-11.272-11.039-11.272-6.438 0-11.037 4.896-11.037 11.272zm54.072-10.348v30.393H314.66V97.95h-4.414v-9.608h4.414v-13.67h11.222v13.67h7.727v9.608h-7.727zm33.857-.092c-2.668-2.493-5.336-2.68-6.531-2.68-2.668 0-4.324 1.295-4.324 3.235 0 1.016.46 2.586 3.587 3.602l2.668.831c3.127 1.017 7.819 2.588 10.117 5.728 1.196 1.664 2.024 4.065 2.024 6.652 0 3.601-1.196 7.205-4.414 10.161-3.221 2.956-7.082 4.249-11.957 4.249-8.279 0-12.971-3.973-15.453-6.65l5.887-6.836c2.207 2.585 5.517 4.617 8.83 4.617 3.127 0 5.517-1.569 5.517-4.341 0-2.495-2.023-3.511-3.494-4.064l-2.576-.924c-2.852-1.016-6.162-2.311-8.553-4.804-1.839-1.94-3.037-4.435-3.037-7.668 0-3.88 1.84-7.112 4.141-9.052 3.127-2.495 7.173-2.864 10.392-2.864 2.944 0 7.635.369 12.693 4.249l-5.517 6.56zm24.307-4.341c3.955-5.543 9.567-6.467 12.969-6.467 11.773 0 19.591 9.145 19.591 21.247 0 12.194-8.093 21.339-19.866 21.339-2.576 0-8.371-.461-12.694-6.466v5.173h-11.222V61h11.222v32.517zm-1.103 14.78c0 6.374 4.598 11.362 11.037 11.362 6.439 0 11.037-4.988 11.037-11.362 0-6.375-4.598-11.27-11.037-11.27-6.439 0-11.037 4.895-11.037 11.27zm51.89 14.041l-18.948-33.996h13.062l12.324 22.725 11.037-22.725h12.693l-32.099 61.154h-12.693l14.624-27.158zM257.101 95.05H229.07v11.085h14.617c-2.217 7.447-7.856 12.67-18.559 12.67-11.63 0-19.834-9.501-19.834-21.182 0-11.68 8.204-21.182 19.834-21.182 6.559 0 12.67 3.078 16.656 8.252l9.387-5.892c-6.039-8.419-15.682-13.445-26.043-13.445C207.412 65.356 193 79.83 193 97.623c0 17.794 13.403 32.268 32.128 32.268 18.726 0 32.128-14.474 32.128-32.268 0-.871-.088-1.72-.155-2.573z"
      fill="#000"
    />
    <path
      d="M141 97h-28v8h19.2c-2.8 12-11.6 22-23.2 26L63 85c4.8-14 18.4-24 34-24 12 0 22.8 6 29.6 15.2l6-5.2c-8-10.8-20.8-18-35.6-18-20.8 0-38.4 14.8-42.8 34.4l52.8 52.8c19.2-4.8 34-22.4 34-43.2zm-88 .4c0 11.2 4.4 22 12.8 30.4 8.4 8.4 19.6 12.8 30.4 12.8L53 97.4z"
      fill="#fff"
    />
    <path
      d="M97 41c-30.8 0-56 25.2-56 56s25.2 56 56 56 56-25.2 56-56-25.2-56-56-56zm-31.2 87.2C57.4 119.8 53 108.6 53 97.8L96.6 141c-11.2-.4-22.4-4.4-30.8-12.8zm40.8 11.6L54.2 87.4C58.6 67.8 76.2 53 97 53c14.8 0 27.6 7.2 35.6 18l-6 5.2C119.8 67 109 61 97 61c-15.6 0-28.8 10-34 24l46 46c11.6-4 20.4-14 23.2-26H113v-8h28c0 20.8-14.8 38.4-34.4 42.8z"
      fill="#639"
    />
    <path d="M465 1h40v192h-40" stroke="#D459AB" />
  </svg>
)

export default Clearspace
