import React from "react"

const PartnershipLockups = () => {
  return (
    <svg
      width="543"
      height="242"
      viewBox="0 0 543 242"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-image="icon"
      focusable="false"
    >
      <path
        d="M183.857 121h-40v11.429h27.429c-4 17.142-16.572 31.428-33.143 37.142l-65.715-65.714c6.858-20 26.286-34.286 48.572-34.286 17.143 0 32.571 8.572 42.286 21.715l8.571-7.429C160.428 68.43 142.143 58.143 121 58.143c-29.714 0-54.857 21.143-61.143 49.143l75.429 75.428c27.428-6.857 48.571-32 48.571-61.714zm-125.714.572c0 16 6.286 31.428 18.286 43.428 12 12 28 18.286 43.428 18.286l-61.714-61.714z"
        fill="#fff"
      />
      <path
        d="M121 41c-44 0-80 36-80 80s36 80 80 80 80-36 80-80-36-80-80-80zM76.429 165.571c-12-12-18.286-28-18.286-43.428l62.286 61.714c-16-.571-32-6.286-44-18.286zm58.285 16.572l-74.857-74.857c6.286-28 31.429-49.143 61.143-49.143 21.143 0 39.429 10.286 50.857 25.714l-8.571 7.429C153.571 78.143 138.143 69.57 121 69.57c-22.286 0-41.143 14.286-48.571 34.286l65.714 65.714c16.571-5.714 29.143-20 33.143-37.142h-27.429V121h40c0 29.714-21.143 54.857-49.143 61.143z"
        fill="#639"
      />
      <path
        d="M21 41c11.046 0 20-8.954 20-20S32.046 1 21 1 1 9.954 1 21s8.954 20 20 20zm200 200c11.046 0 20-8.954 20-20s-8.954-20-20-20-20 8.954-20 20 8.954 20 20 20zM1 41h542"
        stroke="#BC027F"
        strokeWidth=".2"
        strokeMiterlimit="10"
      />
      <path
        d="M1 121h542M1 81h542M1 161h542"
        stroke="#BC027F"
        strokeWidth=".2"
        strokeMiterlimit="10"
        strokeDasharray="1 2"
      />
      <path
        d="M1 201h542M41 1v240"
        stroke="#BC027F"
        strokeWidth=".2"
        strokeMiterlimit="10"
      />
      <path
        d="M81 1v240M121 1v240M161 1v240"
        stroke="#BC027F"
        strokeWidth=".2"
        strokeMiterlimit="10"
        strokeDasharray="1 2"
      />
      <path
        d="M201 1v240"
        stroke="#BC027F"
        strokeWidth=".2"
        strokeMiterlimit="10"
      />
      <path
        d="M281 1v240M241 1v240M321 1v240"
        stroke="#BC027F"
        strokeWidth=".2"
        strokeMiterlimit="10"
        strokeDasharray="1 2"
      />
      <path
        d="M361 1v240"
        stroke="#BC027F"
        strokeWidth=".2"
        strokeMiterlimit="10"
      />
      <path
        d="M441 1v240M401 1v240M481 1v240"
        stroke="#BC027F"
        strokeWidth=".2"
        strokeMiterlimit="10"
        strokeDasharray="1 2"
      />
      <path
        d="M521 1v240"
        stroke="#BC027F"
        strokeWidth=".2"
        strokeMiterlimit="10"
      />
      <path stroke="#BC027F" strokeWidth=".2" d="M273.086 139.052l-40 66" />
      <path
        d="M417.155 152.845a44.975 44.975 0 010-63.69c6.864-6.863 6.864-17.991 0-24.854-6.863-6.864-17.991-6.864-24.854 0A81.241 81.241 0 00369 121a82.64 82.64 0 0023.301 56.699 17.575 17.575 0 1024.854-24.854z"
        fill="#FFD85F"
      />
      <path
        d="M417.155 89.156a44.973 44.973 0 0163.69 0c6.863 6.863 17.991 6.863 24.854 0 6.864-6.864 6.864-17.992 0-24.855A81.243 81.243 0 00449 41a82.64 82.64 0 00-56.699 23.301c-6.864 6.863-6.864 17.991 0 24.855 6.863 6.863 17.991 6.863 24.854 0z"
        fill="#3BB4E7"
      />
      <path
        d="M480.845 152.844a44.972 44.972 0 01-63.69 0c-6.863-6.863-17.991-6.863-24.854 0-6.864 6.864-6.864 17.992 0 24.855A81.241 81.241 0 00449 201a82.638 82.638 0 0056.699-23.301 17.575 17.575 0 00-24.854-24.855z"
        fill="#ED5C68"
      />
      <rect x="261" y="117" width="40" height="8" rx="2" fill="#232129" />
      <rect
        x="285"
        y="101"
        width="40"
        height="8"
        rx="2"
        transform="rotate(90 285 101)"
        fill="#232129"
      />
    </svg>
  )
}

export default PartnershipLockups
